:root {
    --gradient: linear-gradient(145deg, #F02FFD 6.25%, #7873F5 92.75%);
    --gradient-disabled: linear-gradient(145deg, #C49EBF 6.25%, #A9A6C7 92.75%);
    --primary: #F8F7FF;
    --secondary: #333333;
    --tertiary: #C98BF2;
    --tertiary-low: #C98BF266;
    --inactive: #BBBBBB;
    --stdBorderRadius: 50px;
    --maxWidth: 400px;
    --landingPageMaxWidth: 1140px;
    /*--lightInnerShadow: inset 5px 5px 4px rgba(174, 174, 192, 0.2), inset 5px 5px 4px rgba(255, 255, 255, 0.3) ;*/
    /*--bigDropShadow : -5px -5px 10px rgba(255, 255, 255, 0.7),5px 5px 8px rgba(174, 174, 192, 0.3),inset -2px -2px 3px rgba(0, 0, 0, 0.1),inset 2px 2px 3px rgba(255, 255, 255, 0.7);*/
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
    font-size: 16px !important;
}

/* MAIN STUFF */
* {
    font-family: 'Cabinet Grotesk', Lexend, Raleway, Helvetica, Arial, sans-serif;
    padding: 0;
    margin: 0;
    /*font-weight: 500;*/
}

html, body, #root, #load {
    height: 100%;
}

#bodyWrapper {
    /*max-width: var(--maxWidth);*/
    /*margin:auto;*/
    height: 100%;
}

.contentWrapper {
    max-width: var(--maxWidth);
    margin:auto;
    height: 100%;
}

body {
    color: var(--secondary);
    background-color: var(--primary);
}

.motionContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* CSS UTIL */

.active {
    background-color: var(--gradient);
}

.hidden {
    display: none;
}

.scrollable {
    flex: 1 0;
    overflow-y: auto;
}

.noPadding{
    padding: 0 !important;
}

.gradientText {
    background: -webkit-linear-gradient(145deg, #F02FFD 6.25%, #7873F5 92.75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Theme Shadow */

.liteInnerShadow {
    /*box-shadow:*/
    /*inset 5px 5px 4px rgba(174, 174, 192, 0.2),*/
    /*inset 5px 5px 4px rgba(255, 255, 255, 0.3)*/
    /*;*/
}

.bigDropShadow{
    /*box-shadow: */
    /*-5px -5px 10px #FFFFFF, */
    /*5px 5px 8px rgba(174, 174, 192, 0.3), */
    /*inset -2px -2px 3px rgba(0, 0, 0, 0.1),*/
    /*inset 2px 2px 3px #FFFFFF;*/
}

.invisibleStroke {
    /*border: 1px solid;*/
    /*border-image-source: linear-gradient(90deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.6) 100%);*/
}

/* Landing page */

.atoutwrap {
    display: flex;
    justify-content: space-around;
    margin: 30px 0;
    align-items: center;
}


section {
    padding: 10vh 5vw;
}

/* Navbar */

.navbar {
    display:flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content:space-evenly;
    width: 100%;
    border-radius: 20px 20px 0 0;
    height: 50px;
    /*background-color:rgba(248, 248, 248, 0.82);*/
    /*backdrop-filter: blur(29.9px);*/
    /* Est vraiment necessaire ? parce que fait buger l'affichage du chat   */
}

.navbar img {
    width: 25px;
    height: 25px;
}

/* Loader animation */
.loaderBloc {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;

    z-index: 2;
}

.loaderContainer {
    width: 100px;
    height: 100px;
    margin: auto;
    position: relative;
}

.iconLoader {
    width: 100px;
    height: 100px;
    z-index: 4;
    position: absolute;
    top: 0;
    left: 0;
}

.iconLoaderBorder {
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
    animation-fill-mode: forwards;
    z-index: 3;
}

@keyframes spin {
    0% { transform: rotate(0deg);}
    100% { transform: rotate(360deg); }
}

/* Dots animation */
.typing {
    position: relative;
    padding: 5px;
}

.typing span {
    content: '';
    animation: blink 1.5s infinite;
    animation-fill-mode: both;
    height: 10px;
    width: 10px;
    background: #3b5998;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
}

.typing span:nth-child(2) {
    animation-delay: 0.2s;
    margin-left: 15px;
}

.typing span:nth-child(3) {
    animation-delay: 0.4s;
    margin-left: 30px;
}

@keyframes blink {
    0% {
        opacity: 0.1;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 0.1;
    }
}

/* Avatar */
.avatarBorder {
    display: inline-block;
    padding: 2px;
    border-radius: 50%;
    border: solid 1px #7B49FF;
}

.avatarname {
    word-break: break-word;
}

/* APP Banner */
.appbanner {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px auto;
    padding: 5px;
    border-radius: 20px;
    background-color: var(--secondary);
}

.iosInstruction {
    position: absolute;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: var(--primary);
    border-radius: 25px;
    width: 95%;
    color: var(--secondary);
    text-align: left;
}

/* HOME PAGE */
.sliderModal {
    width: 90%;
    max-width: var(--maxWidth);
    height: auto;
    position: relative;
}

.logoContainer {
    width: 90%;
    margin: auto;
    max-width: 300px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoBliss {
    width: 100%;
    max-width: 300px;
    height: auto;
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
}

/* Game HEADER */
.gameHeader {
    position: sticky;
    padding-top: 10px;
    top: 0;
    background-color: #FCFCFC;
    z-index: 10;
    width: 100%;
    text-align: center;
}

.gameHeader > h3 {
    font-size: 1em;
    color: black;
}

.gameHeader > p {
    font-size: 0.8em;
    margin-bottom: 8px;
    color: #A1A1A1;
}

.gameHeader > .avatarHeader {
    display: flex;
    justify-content: space-around;
    margin-bottom: 8px;
}

.gameHeader .eliminatedcrossHeader {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 15;
}

.profilModal {
    max-width: var(--maxWidth);
    width: 100%;
}

.profilModal > .usercard {
    margin: auto;
    width: 95%;
    aspect-ratio: 3/4;
    position: relative;
}

/*JOIN PAGE*/

.buttonGoBackward {
    /*display: flex;*/
    /*justify-content: start;*/
    /*align-items: center;*/
    /*border-radius: 18px;*/
    /*width: 35px;*/
    /*height: 35px;*/
    transform: translateX(-30px);
    /*background-color: var(--primary);*/
    /*box-shadow: var(--bigDropShadow);*/
    cursor: pointer;
}

.cgvLink {
    color: var(--primary);
}

/* Mobile Authentication */
.otpInput {
    width: 45px;
    height: 45px;
    padding: 0;
    font-size: 24px;
    text-align: center;
    margin-right: 12px;
    text-transform: uppercase;
    color: #494949;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    background: #fff;
    background-clip: padding-box;
}

.otpInput:focus {
    outline:none;
    border-color: red
}

/* Register Component */

.registerinfo {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: var(--tertiary-low);
    border: solid 1px #C98BF2A5;
    border-radius: 15px;
    gap: 15px;
}

.infobanner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background: linear-gradient(145deg, #F02FFD 6.25%, #7873F5 92.75%);
}

.maxSize {
    display: grid;
    grid-template-columns: 1fr fit-content(100%);
}

/* Would you rather componenent */

.choice > p {
    font-size: 1.2em;
}

.choice {
    flex: 1 1 auto;
    height: 100%;
    max-height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    background-color: #333333;
    position: relative;
    color: white;
    text-align: center;
}

.activeChoice {
    background: var(--gradient);
    box-shadow: 1px 1px 5px black;
}

.inactiveChoice {
    background: var(--inactive);
}

.vs {
    background: var(--gradient);
    color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: solid 10px var(--primary);
    text-align: center;
    position: absolute;
    bottom: -46px; /* 16 de margin + 5 de border + 25 de height */
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
}

.vsBachelor {
    border-radius: 50%;
    text-align: center;
    position: absolute;
    bottom: -46px; /* 16 de margin + 5 de border + 25 de height */
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
}

.fade-enter {
    opacity: 0;
    transform: translateY(-20px);
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.5s ease;
}

.fade-exit {
    opacity: 1;
    transform: translateY(0);
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: all 0.5s ease;
}

/* Début du custom Input */
.answerInput {
    display: flex;
    align-items: center;
    border-radius: 15px;
    background-color: Var(--primary);
    padding: 10px;
    border-color: rgba(0, 0, 0, 0.2);
    border-width: 1px;
    border-style: solid;
}

.answerInput input {
    flex: 1;
    border: none;
    outline: none;
    padding: 8px;
    font-size: 16px;
    background-color: transparent;
}

.answerInput Button:hover {
    opacity: 0.7;
}

.answerInput Button:focus {
    outline: none;
}

.roundIconbutton {
    /* background-color:linear-gradient(145deg, #F02FFD 6.25%, #7873F5 92.75%) !important; */
    border-radius: 15px;
    padding: 0;
    width: 2em;
    height: 2em;
}

.roundIconbutton:disabled {
    background: red !important;
}

.longAnswerInput {
    display: flex;
    align-items: flex-end;
    border-radius: 15px;
    background-color: var(--primary);
    padding: 10px;
    border-color: rgba(0, 0, 0, 0.2);
    border-width: 1px;
    border-style: solid;
}

.answerTextarea {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    background-color: transparent;
    border: none;
    outline: none;
    resize: none;
    overflow: auto;
    flex: 1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.answerTextarea::placeholder {
    color: #888;
}

.answerTextarea:focus {
    border-color: #2e86de;
}
  
  .longAnswerInput Button:hover {
    opacity: 0.7;
  }
  
  .longAnswerInput Button:focus {
    outline: none;
  }

.scoreAnimation {
    transition: width 0.5s ease-in-out;
}

.classementAnimation {
    transition: transform 0.5s;
}

/* Build component */

.buildGrid {
    background-color: #FFFFFF ;
    border-radius: 15px ;
    border: 1px solid #BBBBBB;
}

.activeChip {
    /* Sinon go passer par les sx */
    background: var(--gradient);
    color: white !important;
}

.previouslySelectedChip {
    /*background: var(--gradient);*/
    /* Je sais pas pq ca fonctionne avec le var gradient mais pas l'autre */
    /* Obligé de mettre !important sur le background si on souhaite passer les les classes */
    /* Sinon go passer par les sx */
    background-color: #C98BF2 !important;
    box-shadow: inset 1px 1px 2px black;
}

.basicChip {
    background-color: var(--secondary) !important;
    color: white !important
}

/* Sentence component */

/* .sentence textarea {
    color: black;
    border: solid 1px black;
    width: 100%;
    max-width: 300px;
    padding: 7px 12px;
    display: block;
    margin: 20px auto;
    border-radius: 5px;
} */

.answer {
    color: white;
    background: var(--secondary);
    border-radius: 15px 15px 15px 0;
    padding: 10px;
    word-break: break-word;
    text-align: left;
}

.activeAnswer {
    background: var(--gradient);
}

.colorAnswer {
    background: var(--tertiary);
}

.eliminateAnswer {
    background: var(--inactive);
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    40% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }

}

.blinking {
    animation: blink 0.8s ease-in-out;
}

/*Eliminate*/
.eliminate .response {
    color: #432b91;
    background: #F2F2F2;
    border-radius: 15px 15px 15px 0;
    padding: 15px;
    border: solid 1px;
    cursor: pointer;
    font-size: 0.9em;
}

.eliminate .response:hover {
    background-color: #d0a3e7 !important;
    color: white;
}

.questionEliminate {
    color: white !important;
    background: linear-gradient(145.18deg, rgba(240, 47, 253) 6.25%, rgba(120, 115, 245) 92.71%);
    /*background: linear-gradient(131.33deg, #EC77AB 7.13%, #7873F5 98.33%);*/
    border-radius: 15px 15px 15px 0;
    padding: 10px;
    width: 80%;
    margin: 5px auto;
    font-size: 1em;
    text-align: center;
}

.eliminate .answer-active .response {
    box-shadow: 1px 1px 10px black;
    background-color: #B275D1 !important;
    color: white !important;
}

.response {
    word-break: break-word;
}

/* Messages */
.chat {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 0;
    font-size: 0.9em;
    width: 100%;
    height: 100%;
}

.chatHeader {
    align-items: center;
    background-color: #FCFCFC;
    padding-top: 0.5rem;
}

.chatHeaderInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.5em;
    text-align: left;
}

.chatHeaderBachelor {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    flex: 1 1 auto;
    font-size: 0.9em;
}

.chatNotification {
    position: absolute;
    top: -4px;
    right: -6px;
    padding: 4px 8px;
    border-radius: 50%;
    background: red;
    color: white;
    z-index: 15;
}

.messagesColumn {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;
    color: black;
}

.other {
    background: rgba(0, 0, 0, 0.08);
    color: black;
    max-width: 300px;
    border-radius: 15px 15px 15px 0;
    padding: 10px;
    width: 80%;
    align-self: flex-start;
    margin: 10px;
}

.me {
    background: linear-gradient(145.18deg, rgba(240, 47, 253,0.8) 6.25%, rgba(120, 115, 245, 0.8) 92.71%);
    /*background: linear-gradient(131.33deg, #EC77AB 7.13%, #7873F5 98.33%);*/
    max-width: 300px;
    width: 80%;
    margin: 10px;
    border-radius: 15px 15px 0 15px;
    padding: 10px;
    align-self: flex-end;
    color: white;
}

.msgText {
    word-break: break-word;
}

.chat input[type="text"] {
    color: black;
    border: solid 1px black;
    padding: 7px 12px;
    display: block;
    border-radius: 25px;
    flex: 1 1 auto !important;
}

.chat input:focus {
    outline: none;
    border: solid 1px #7873F5;
}

.chat button[type="submit"] {
    border: none;
    margin: 5px;
    flex: 0;
    min-width: 40px;
    width: auto;
    height: 30px;
    cursor: pointer;
    background-image: url('../assets/send_text.png');
    background-size: contain;
    background-repeat: no-repeat;

}

.chatActiveUser {
    /*background: linear-gradient(87.08deg, #FA457E 6.8%, #7B49FF 102.07%);*/
    background: linear-gradient(145.18deg, rgba(240, 47, 253) 6.25%, rgba(120, 115, 245) 92.71%);
    color: white;
}

.choosematch {
    display: flex;
    justify-content: center;
    margin: 0.5rem;
}

.choosematch .warning > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.choosematch button {
    color: white;
    padding: 5px 1rem;
    font-size: 1rem;
    border: none;
    font-weight: bold;
    cursor: pointer;
    margin: 0.5rem;
    background: linear-gradient(145.18deg, rgba(240, 47, 253) 6.25%, rgba(120, 115, 245) 92.71%);
    border-radius: 25px;
}

/* Message input and button */
.sendMessageContainer {
    width: 100%;
    flex: 0 0;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    /*padding: 10px 10px 10px 10px;*/
}

.game {
    text-align: center;
    font-weight: bold;
    background: var(--gradient);
    /*background: linear-gradient(131.33deg, #EC77AB 7.13%, #7873F5 98.33%);*/
    border-radius: 15px 15px 15px 0;
    padding : 15px;
    margin : 10px;
}

.game > p {
    color:white;
}

.gameAnswer {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px 10px 10px 10px;
    padding : 10px;
    margin: 5px;
}

.chainSuccess {
    /*background: #7ABD7E;*/
    background: var(--gradient);
}

.chainFailure {
    /*background: #FF6961;*/
    background: var(--secondary);
}

/* Match popup */

.glow {
    box-shadow:
        0 0 60px 30px #fff,  /* inner white */
        0 0 100px 60px #f0f, /* middle magenta */
        0 0 140px 90px #0ff; /* outer cyan */
}

/* Loser popup */

.loser h3 {
    font-size: 1.5em;
    color: #111111;
}

.loser p {
    font-size: 1.2em;
    color: #111111;
}

.alphalogo {
    width: 50%;
    height: auto;
    max-width: 250px;
    min-width: 100px;
    padding: 1rem;
}

.iconInstagram {
    width: auto;
    height: 20px;
    margin-left: 5px;
}

.followInsta {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
}


/*!*Eliminate Profils*!*/

.bigcircle {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 300px;
    max-height: 300px;
    margin: auto;
}

.bigcircle .contender {
    position: absolute;
    top: 50%; left: 50%;
    width: 100px;
    height: 100px;
    margin: calc( -100px / 2 );
    border-radius: 50%;
}

.bigcircle .bachelor {
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
}

.avatarcontainer {
    position: relative;
}

.eliminatedcross {
    position: absolute;
    top: -5px;
    left: 5px;
    width: 100%;
    height: 100%;
    z-index: 15;
}

@keyframes cross-appear {
    0% {
        visibility: hidden;
        opacity: 0;
        transform: scale(2);
    }
    50% {
        visibility: visible;
    }
    100% {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
    }
}

/* -------------- PAGE MATCH --------------- */

/* Matches */

.matchesContainer {
    flex: 1 0;
    overflow-y: auto;
}

.matchBox {
    display: flex;
    padding: 0.5em 1em;
    cursor: pointer;
    /*align-items: center; */
    /*Pas tres beau en center*/
}

.matchNewMessageNotif {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) translateX(20%);
    background: var(--gradient);
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.matchesChatModalAction {
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    background-color: #ffffff;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
}

.textOverflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* CHAT */

.matchChatContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    max-width: var(--maxWidth);
}

.matchChatHeader {
    display: flex;
    align-items: center;
    padding: 1em;
    border-bottom: 1px solid var(--secondary);
}

/* GAME TRANSITION EXPLANATION */
.gameExplanation {
    max-width: 320px;
    height: auto;
    width: 90%;
    display: block;
    margin: auto;
}

/* ANCIEN CSS A LAISSER AU CAS OU MAIS ON REPARS DE 0 AVEC MUI*/

/*.coloredbody {*/
/*    !*background: linear-gradient(145.18deg, rgba(240, 47, 253, 0.4) 6.25%, rgba(120, 115, 245, 0.4) 92.71%);*!*/
/*    !*background: linear-gradient(160.67deg, rgba(240, 47, 253, 0.4) -5.9%, rgba(120, 115, 245, 0.4) 106.92%), #FCFCFC;*!*/
/*}*/

/*.coloredbody p, .coloredbody h1, .coloredbody h2, .coloredbody h3, .coloredbody h4, .coloredbody h5, .coloredbody h6 {*/
/*    color: #333333;*/
/*}*/

/**:disabled {*/
/*    background-color: dimgrey;*/
/*    color: linen;*/
/*    opacity: 1;*/
/*}*/

/*input[type='text'],*/
/*input[type='number'],*/
/*textarea {*/
/*    font-size: 16px;*/
/*}*/

/*!* ------------ HOME ------------- *!*/
/*.home input[type="text"] {*/
/*    color: black;*/
/*    !*border: solid 1px black;*!*/
/*    width: 50%;*/
/*    padding: 7px 12px;*/
/*    display: block;*/
/*    text-align: center;*/
/*    margin: 20px auto;*/
/*    !*border-radius: 25px;*!*/
/*    background: transparent;*/
/*    border: none;*/
/*    border-bottom: 1px solid #000000;*/
/*}*/

/*.home input:focus {*/
/*    outline: none;*/
/*    border-bottom: 1px solid #7873F5;*/
/*    !*border: solid 1px #7873F5;*!*/
/*}*/

/*button[type="submit"] {*/
/*    color: white;*/
/*    padding: 0.5rem 1rem;*/
/*    font-size: 1rem;*/
/*    border: none;*/
/*    margin: 10px 0 2rem 0;*/
/*    font-weight: bold;*/
/*    width: 150px;*/
/*    height: 40px;*/
/*    cursor: pointer;*/
/*    !*background: linear-gradient(87.08deg, #FA457E 6.8%, #7B49FF 102.07%);*!*/
/*    background: linear-gradient(145.18deg, rgba(240, 47, 253) 6.25%, rgba(120, 115, 245) 92.71%);*/

/*    border-radius: 25px;*/
/*}*/

/*button[type="submit"]:hover {*/
/*    !*background: linear-gradient(87.08deg, #FA457E 50%, #7B49FF 150%);*!*/
/*    background: linear-gradient(145.18deg, rgba(240, 47, 253) 50%, rgba(120, 115, 245) 150%);*/

/*}*/

/*button[type="submit"]:active {*/
/*    !*background: linear-gradient(87.08deg, #FA457E 5%, #7B49FF 50%);*!*/
/*    background: linear-gradient(145.18deg, rgba(240, 47, 253) 5%, rgba(120, 115, 245) 50%);*/


/*}*/

/*button[type="submit"]:disabled {*/
/*    cursor: auto;*/
/*    !*background: linear-gradient(87.08deg, #FA457E 5%, #7B49FF 50%);*!*/
/*    background: linear-gradient(145.18deg, rgba(240, 47, 253) 6.25%, rgba(120, 115, 245) 92.71%);*/
/*    opacity: 0.5;*/
/*    color: #dddddd;*/
/*}*/

/*.btnChoice {*/
/*    color: white;*/
/*    padding: 0.2rem 0.8rem;*/
/*    font-size: 0.8rem;*/
/*    border: none;*/
/*    margin: 10px;*/
/*    font-weight: bold;*/
/*    cursor: pointer;*/
/*    border-radius: 25px;*/
/*    background: #c0c0c0;*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/

/*.btnChoice > img {*/
/*    height: 20px;*/
/*    margin: 2px 5px 2px 1px;*/
/*}*/

/*.checked {*/
/*    !*background: linear-gradient(87.08deg, #FA457E 6.8%, #7B49FF 102.07%);*!*/
/*    background: linear-gradient(145.18deg, rgba(240, 47, 253) 6.25%, rgba(120, 115, 245) 92.71%);*/
/*}*/

/*.rounded-box {*/
/*    width: 150px;*/
/*    height: 150px;*/
/*    display: block;*/
/*    margin: 0 auto;*/
/*}*/

/*.outer {*/
/*    width: 100% !important;*/
/*    height: 100% !important;*/
/*    margin: auto;*/
/*    background-size: contain;*/
/*    border-radius: 100%;*/
/*    position: relative;*/
/*}*/

/*.avatarimg {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    text-align: center;*/
/*    object-fit: cover;*/
/*    color: transparent;*/
/*    text-indent: 10000px;*/
/*    overflow: clip;*/
/*}*/

/*.inner {*/
/*    background-color: #ff1414;*/
/*    width: 40px;*/
/*    height: 40px;*/
/*    border-radius: 100%;*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*    right: 0;*/
/*}*/

/*.inner:hover {*/
/*    background-color: #5555ff;*/
/*}*/

/*.inputimage {*/
/*    opacity: 0;*/
/*    overflow: hidden;*/
/*    position: absolute;*/
/*    z-index: 1;*/
/*    width: 40px;*/
/*    height: 40px;*/
/*}*/
/*.inputimage + label {*/
/*    font-size: 1.25rem;*/
/*    text-overflow: ellipsis;*/
/*    white-space: nowrap;*/
/*    display: inline-block;*/
/*    overflow: hidden;*/
/*    width: 40px;*/
/*    height: 40px;*/
/*    pointer-events: none;*/
/*    cursor: pointer;*/
/*    line-height: 40px;*/
/*    text-align: center;*/
/*}*/
/*.inputimage + label svg {*/
/*    fill: #fff;*/
/*    cursor: pointer;*/
/*}*/

/*!* TWO CHOICES *!*/

/*.choice > p {*/
/*    font-size: 1.1em;*/
/*    text-align: center;*/
/*}*/

/*.choice {*/
/*    flex: 1 1 auto;*/
/*    justify-content: center;*/
/*    height: 100%;*/
/*    cursor: pointer;*/
/*    background-color: white;*/
/*    border: solid 1px #CF76BE;*/
/*    color: #7873F5;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    padding: 10px;*/
/*    max-height: 200px;*/
/*    position: relative;*/
/*    text-align: center;*/
/*    font-size: 1.1em;*/
/*}*/

/*.vsicon {*/
/*    position: absolute;*/
/*    width: auto;*/
/*    height: 50px;*/
/*    bottom: -25px;*/
/*    left: 50%;*/
/*    transform: translateX(-50%);*/
/*    z-index: 10;*/
/*    background: white;*/
/*    border-radius: 50%;*/
/*    border: 1px solid black;*/
/*}*/

/*!* Sentence *!*/
/*.sentence textarea {*/
/*    color: black;*/
/*    border: solid 1px black;*/
/*    width: 100%;*/
/*    max-width: 300px;*/
/*    padding: 7px 12px;*/
/*    display: block;*/
/*    margin: 20px auto;*/
/*    border-radius: 5px;*/
/*}*/
/*!* --------- Four cards ---------- *!*/

/*.question {*/
/*    color: white;*/
/*    background: linear-gradient(145.18deg, rgba(240, 47, 253) 6.25%, rgba(120, 115, 245) 92.71%);*/
/*    !*background: linear-gradient(131.33deg, #EC77AB 7.13%, #7873F5 98.33%);*!*/
/*    border-radius: 15px 15px 15px 0;*/
/*    padding: 15px;*/
/*    margin-right: 5px;*/
/*    font-size: 0.9em;*/
/*    word-break: break-word;*/
/*    text-align: center;*/
/*}*/

/*.FourCards .card {*/
/*    height: 20vh;*/
/*    background-size: cover;*/
/*    background-position: center;*/
/*    border-radius: 5px;*/
/*    padding: 1rem;*/
/*    cursor: pointer;*/
/*    transition: all 0.3s ease;*/
/*}*/

/*.FourCards .cardActive {*/
/*    box-shadow: 4px 4px 30px green;*/
/*    opacity: 0.8;*/
/*}*/

/*.bigcard {*/
/*    margin: auto;*/
/*    text-align: center;*/
/*    max-width: 250px;*/
/*    justify-content: center;*/
/*    padding: 1rem;*/
/*    cursor: pointer;*/
/*    transition: all 0.3s ease;*/
/*    font-size: 1.5em;*/
/*    font-weight: bold;*/
/*}*/

/*.bigcard p {*/
/*    margin-top: 10px;*/
/*    margin-bottom: 0;*/
/*}*/

/*@media screen and (max-width: 500px) {*/
/*    .bigcard {*/
/*        font-size: 1em;*/
/*    }*/

/*    .FourCards .card {*/
/*        height: 100px;*/
/*    }*/
/*}*/

/*!*!*Eliminate*!*!*/
/*.eliminate .response {*/
/*    color: #432b91;*/
/*    background: #F2F2F2;*/
/*    border-radius: 15px 15px 15px 0;*/
/*    padding: 15px;*/
/*    border: solid 1px;*/
/*    cursor: pointer;*/
/*    font-size: 0.9em;*/
/*}*/

/*.eliminate .response:hover {*/
/*    background-color: #d0a3e7 !important;*/
/*    color: white;*/
/*}*/

/*.questionEliminate {*/
/*    color: white !important;*/
/*    background: linear-gradient(145.18deg, rgba(240, 47, 253) 6.25%, rgba(120, 115, 245) 92.71%);*/
/*    !*background: linear-gradient(131.33deg, #EC77AB 7.13%, #7873F5 98.33%);*!*/
/*    border-radius: 15px 15px 15px 0;*/
/*    padding: 10px;*/
/*    width: 80%;*/
/*    margin: 5px auto;*/
/*    font-size: 1em;*/
/*    text-align: center;*/
/*}*/

/*.eliminate .answer-active .response {*/
/*    box-shadow: 1px 1px 10px black;*/
/*    background-color: #B275D1 !important;*/
/*    color: white !important;*/
/*}*/

/*.response {*/
/*    word-break: break-word;*/
/*}*/

/*!* ------------ MATCMAKING ROOM ------------- *!*/

/*!* the 'wave', same shape and size as its parent *!*/
/*!** Prevent scrollbars to appear when waves go out of bound *!*!*/
/*.sonar-wrapper {*/
/*    position: relative;*/
/*    z-index: 0;*/
/*    overflow: hidden;*/
/*    padding: 8rem 0;*/
/*}*/

/*!* The circle *!*/
/*.sonar-emitter {*/
/*    position: relative;*/
/*    margin: 0 auto;*/
/*    width: 50px;*/
/*    height: 50px;*/
/*    border-radius: 9999px;*/
/*}*/

/*.sonar-wave {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    border-radius: 9999px;*/
/*    background-color: white;*/
/*    border: solid 2px #432b91;*/
/*    opacity: 0;*/
/*    z-index: 1;*/
/*    pointer-events: none;*/
/*    animation: sonarWave 3s linear infinite;*/
/*}*/

/*.wavesimg {*/
/*    background: url('./assets/waves.png') center no-repeat;*/
/*    background-size: contain;*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    width: 75%;*/
/*    height: 75%;*/
/*    transform: translate(-50%, -50%);*/
/*    z-index: 0;*/
/*    pointer-events: none;*/
/*    animation: sonar 3s linear infinite;*/
/*}*/

/*@keyframes sonar {*/
/*    0% {*/
/*        transform: translate(-50%, -50%) scale(0);*/
/*        opacity: 1;*/
/*    }*/
/*    75% {*/
/*        transform: translate(-50%, -50%) scale(0.9);*/
/*        opacity: 0.5;*/
/*    }*/
/*    100% {*/
/*        transform: translate(-50%, -50%) scale(1.2);*/
/*        opacity: 0;*/
/*    }*/
/*}*/

/*@keyframes sonarWave {*/
/*    0% {*/
/*        opacity: 0.4;*/
/*        transform: scale(0);*/
/*    }*/
/*    40% {*/
/*        opacity: 0;*/
/*        transform: scale(1.2);*/
/*    }*/
/*    100% {*/
/*        transform: scale(3);*/
/*        opacity: 0;*/
/*    }*/
/*}*/

/*.two-chat {*/
/*    height: 100%;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: space-between;*/
/*}*/

/*!*Match*!*/
/*.match {*/
/*    color: white;*/
/*}*/

/*.match input[type="text"]{*/
/*    color: black;*/
/*    border: solid 1px black;*/
/*    width: 80%;*/
/*    max-width: 200px;*/
/*    padding: 7px 12px;*/
/*    display: block;*/
/*    margin: 20px auto;*/
/*    border-radius: 25px;*/
/*}*/

/*.match h3 {*/
/*    font-size: 1.5em;*/
/*    color: #111111;*/
/*}*/

/*.match p {*/
/*    font-size: 1.1em;*/
/*    color: #111111;*/
/*}*/

/*.btnlogo{*/
/*    width: 30px;*/
/*    height: 30px;*/
/*    cursor: pointer;*/
/*}*/

/*.btnlogo:hover, .btnlogo:active {*/
/*    opacity: 0.7;*/
/*}*/


/*.show-counter {*/
/*    padding: 0.5rem;*/
/*}*/

/*.show-counter {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    font-weight: 700;*/
/*    font-size: 1.25rem;*/
/*    line-height: 1.75rem;*/
/*    padding: 0.5rem;*/
/*    border: 1px solid #ebebeb;*/
/*    border-radius: 0.25rem;*/
/*    text-decoration: none;*/
/*    color: #000;*/
/*}*/

/*.show-counter .countdown {*/
/*    line-height: 1.25rem;*/
/*    padding: 0 0.75rem 0 0.75rem;*/
/*    align-items: center;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/

/*.show-counter .countdown > p {*/
/*    margin: 0;*/
/*}*/

/*.show-counter .countdown > span {*/
/*    text-transform: uppercase;*/
/*    font-size: 0.75rem;*/
/*    line-height: 1rem;*/
/*}*/

/*footer {*/
/*    text-align: center;*/
/*}*/

/*footer p {*/
/*    text-align: center;*/
/*    margin-top: auto;*/
/*    padding: 10px;*/
/*    font-size: 0.9em;*/
/*}*/

/*footer > div {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/

/*.btnInsta {*/
/*    margin-right: 20px;*/
/*    text-decoration: none;*/
/*}*/

/*.errormessage {*/
/*    background-color: #fce4e4;*/
/*    border: 1px solid #cc0033;*/
/*    padding: 10px 15px;*/
/*    margin: 10px;*/
/*    color: #cc0033;*/
/*    font-size: 0.8em;*/
/*    font-weight: bold;*/
/*    line-height: 20px;*/
/*    text-shadow: 1px 1px rgba(250,250,250,.3);*/
/*}*/

/*.flexColumn {*/
/*    display: flex;*/
/*    height: 100%;*/
/*    flex-direction: column;*/
/*}*/

/*.comment button {*/
/*    color: white;*/
/*    padding: 0.5rem 1rem;*/
/*    font-size: 1rem;*/
/*    border: none;*/
/*    font-weight: bold;*/
/*    cursor: pointer;*/
/*    background: linear-gradient(145.18deg, rgba(240, 47, 253) 6.25%, rgba(120, 115, 245) 92.71%);*/
/*    !*background: linear-gradient(87.08deg, #FA457E 6.8%, #7B49FF 102.07%);*!*/
/*    border-radius: 25px;*/
/*}*/

/*.comment button:disabled {*/
/*    cursor: auto;*/
/*    !*background: linear-gradient(87.08deg, #FA457E 5%, #7B49FF 50%);*!*/
/*    background: linear-gradient(145.18deg, rgba(240, 47, 253) 6.25%, rgba(120, 115, 245) 92.71%);*/
/*    opacity: 0.5;*/
/*    color: #dddddd;*/
/*}*/

/*.comment textarea {*/
/*    color: black;*/
/*    border: solid 1px black;*/
/*    width: 100%;*/
/*    box-sizing: border-box;*/
/*    padding: 7px 12px;*/
/*    display: block;*/
/*    margin: 1rem auto 0.5rem auto;*/
/*    border-radius: 5px;*/
/*}*/